var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('v-row',{staticClass:"justify-center pa-0 mt-n4 ma-n3",attrs:{"no-gutter":"","dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('question-explanation-video',{attrs:{"videoId":_vm.item.ytid},on:{"ready":_vm.onPlayerReady}}),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[(_vm.item.title)?_c('v-card-actions',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.isHindi ? _vm.item.title_hi ? _vm.item.title_hi : _vm.item.title : _vm.item.title)+" "),_c('v-spacer'),(_vm.item)?_c('VideoActions',{attrs:{"video":_vm.item}}):_vm._e()],1):_vm._e(),(_vm.item && _vm.item.user)?_c('v-divider'):_vm._e(),(_vm.item && _vm.item.user)?_c('v-card-title',[_c('div',{staticClass:"d-flex",on:{"click":function($event){return _vm.$router.push({
                name: 'profile',
                params: { id: _vm.item.user.username },
              })}}},[_c('v-avatar',{staticClass:"mr-2",attrs:{"contain":"","size":"32"}},[_c('v-img',{attrs:{"src":_vm.item.user.avatar}})],1),_c('div',[_c('div',{staticClass:"body"},[_vm._v(" "+_vm._s(_vm.item.user.display_name)+" ")])])],1),_c('v-spacer')],1):_vm._e(),_c('v-divider'),_c('v-card-text',{},[(_vm.item.description)?_c('latex-html',{attrs:{"html":_vm.isHindi
                ? _vm.item.description_hi
                  ? _vm.item.description_hi
                  : _vm.item.description
                : _vm.item.description}}):_vm._e()],1)],1),(!_vm.relatedVideos)?_c('v-col',{staticClass:"justify-center d-flex",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mt-4 mx-auto",attrs:{"outlined":"","large":""},on:{"click":function($event){return _vm.$router.push({ name: 'videos' })}}},[_vm._v(_vm._s(_vm.$lang("WATCHOTHERVIDEOS"))+" ")])],1):_vm._e()],1)],1),(_vm.relatedVideos)?_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","md":"3"}},[_c('v-row',{staticClass:"mt-1",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',{staticClass:"ma-0 pa-0"},[_vm._v(" "+_vm._s(_vm.$lang("Related"))+" "+_vm._s(_vm.$lang("Videos"))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.isListView = !_vm.isListView}}},[_c('v-icon',[_vm._v(_vm._s(_vm.isListView ? "mdi-view-list" : "mdi-view-grid"))])],1)],1)],1),_vm._l((_vm.relatedVideos),function(video){return _c('v-col',{key:video.id},[_c('video-card',{attrs:{"video":video,"isList":!_vm.isListView},on:{"clicked":function (vdo) { return _vm.handleClick(vdo); }}})],1)})],2),_c('v-btn',{staticClass:"mt-4 mx-2",attrs:{"outlined":"","large":""},on:{"click":function($event){return _vm.$router.push({ name: 'videos' })}}},[_vm._v(_vm._s(_vm.$lang("WATCHOTHERVIDEOS"))+" ")])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }