<template>
  <v-row no-gutter class="justify-center pa-0 mt-n4 ma-n3" dense v-if="item">
    <v-col cols="12" md="8">
      <question-explanation-video @ready="onPlayerReady" :videoId="item.ytid" />

      <!-- <v-card-text v-if="player" class="d-flex flex-row mb-4">
        <v-spacer></v-spacer> {{ player.getDuration().toString().fancyTime() }}
      </v-card-text> -->
      <v-row>
        <v-col cols="12" class="d-flex flex-column">
          <v-card-actions class="title" v-if="item.title">
            {{
              isHindi
                ? item.title_hi
                  ? item.title_hi
                  : item.title
                : item.title
            }}
            <v-spacer></v-spacer>
            <VideoActions v-if="item" :video="item" />
          </v-card-actions>
          <v-divider v-if="item && item.user"></v-divider>
          <v-card-title v-if="item && item.user">
            <div
              class="d-flex"
              @click="
                $router.push({
                  name: 'profile',
                  params: { id: item.user.username },
                })
              "
            >
              <v-avatar contain size="32" class="mr-2">
                <v-img :src="item.user.avatar"></v-img>
              </v-avatar>

              <div>
                <div class="body">
                  {{ item.user.display_name }}
                </div>
              </div>
            </div>
            <v-spacer></v-spacer>
            <!-- <v-chip small outlined>{{ item.created_at | fromNow }}</v-chip> -->
            <!-- <v-btn text color="red white--text">{{ $lang("subscribe") }}</v-btn>
            <v-btn icon color="gray"> <v-icon>mdi-bell</v-icon></v-btn> -->
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text class="">
            <latex-html
              v-if="item.description"
              :html="
                isHindi
                  ? item.description_hi
                    ? item.description_hi
                    : item.description
                  : item.description
              "
            />
          </v-card-text>
        </v-col>
        <v-col cols="12" v-if="!relatedVideos" class="justify-center d-flex">
          <v-btn
            class="mt-4 mx-auto"
            outlined
            @click="$router.push({ name: 'videos' })"
            large
            >{{ $lang("WATCHOTHERVIDEOS") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="3" v-if="relatedVideos" class="d-flex flex-column">
      <v-row dense class="mt-1">
        <v-col cols="12">
          <v-card-title class="ma-0 pa-0">
            {{ $lang("Related") }} {{ $lang("Videos") }}
            <v-spacer></v-spacer>
            <v-btn icon @click="isListView = !isListView"
              ><v-icon>{{
                isListView ? "mdi-view-list" : "mdi-view-grid"
              }}</v-icon></v-btn
            >
          </v-card-title>
        </v-col>
        <v-col v-for="video in relatedVideos" :key="video.id">
          <video-card
            :video="video"
            :isList="!isListView"
            @clicked="(vdo) => handleClick(vdo)"
          />
        </v-col>
      </v-row>
      <v-btn
        class="mt-4 mx-2"
        outlined
        @click="$router.push({ name: 'videos' })"
        large
        >{{ $lang("WATCHOTHERVIDEOS") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import QuestionExplanationVideo from "@/components/QuestionExplanationVideo";
import LatexHtml from "@/components/LatexHtml";
import VideoCard from "../modules/VideoCard.vue";
import VideoActions from "@/app/ugcp/modules/VideoActions";

export default {
  name: "video-single",
  components: {
    QuestionExplanationVideo,
    LatexHtml,
    VideoCard,
    VideoActions,
  },
  data() {
    return {
      loading: false,
      isListView: !this.$vuetify.breakpoint.smAndUp,
      item: null,
      relatedVideos: null,
      player: null,
    };
  },
  computed: {},
  methods: {
    handleClick(video) {
      this.$router.push({
        name: "video",
        params: { id: video.id },
        query: {
          parent_page: this.$route.query.parent_page,
          ytid: video.ytid,
          slug: video.slug,
        },
      });
    },
    onPlayerReady(player) {
      // console.log('player...', player)
      this.player = player;
    },
    fetchItem() {
      this.loading = true;
      bus.$emit("showWait");

      axios
        .get("videos/public/" + this.$route.params.id)
        .then((res) => {
          this.item = res.data;
          this.fetchRelatedItem(this.item.id);
        })
        .catch((err) => {
          this.$router.replace({ name: "videos" });
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
    fetchRelatedItem(id) {
      this.loading = true;
      axios
        .get("videos/public/" + id + "/related?page=1")
        .then((res) => {
          this.relatedVideos = res.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.fetchItem();
  },
};
</script>

<style>
</style>

